import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_GETTING_STARTED_DATA } from "../../../../common/data/SecD3v";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, {
  Row,
  Col,
  AboutTitle,
  ContactDetails,
  ColumnContents,
} from "./section.style";

const Section = () => {
  const { sections } = SECTION_GETTING_STARTED_DATA;

  return (
    <>
      <SectionArea id="build-your-application">
        <Container>
          <Row className="rowContents">
            <Col className="blockTitle">
              <Row>
                <img
                  className="imageSection1"
                  src={sections.imageGettingStarted2}
                  alt="sec-d3v-getting-started"
                />
              </Row>
            </Col>
            <Col className="blockTitle">
              <Row>
                <Heading as="h2" content={sections.title2} />
                <Text as="p" content={sections.title_linetag2} />
              </Row>
            </Col>
          </Row>
        </Container>
      </SectionArea>
    </>
  );
};

export default Section;
