import styled from "styled-components";
const OurProductArea = styled.section`
  background-color: white;
  padding-bottom: 80px;
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 80px;
  }
  .container {
    max-width: 1000px;
    .blockTitle {
      text-align: center;
      max-width: 577px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      @media (max-width: 1600px) {
        margin-bottom: 40px;
      }
      h2 {
        font-weight: bold;
        font-size: 36px;
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: #132636;
        margin: 0;
        margin-bottom: 15px;
        @media (max-width: 1600px) {
          font-size: 30px;
          line-height: 1.3;
          max-width: 468px;
          margin-left: auto;
          margin-right: auto;
        }
        @media (max-width: 768px) {
          font-size: 28px;
        }
        @media (max-width: 375px) {
          font-size: 22px;
        }
      }
      p {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 2;
        color: #132636;
        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
    .bannerImage {
      text-align: center;
      margin-bottom: 70px;
      img {
        max-width: 100%;
      }
    }
    .bannerVideo {
      margin: auto;
      text-align: center;
      margin-bottom: 70px;
      border-radius: 12px;
      overflow: hidden;
      z-index: 1;
      height: 350px;
      width: 860px;
      box-shadow: 4px 11px 16px -5px rgba(125, 125, 125, 0.62);
      -webkit-box-shadow: 4px 11px 16px -5px rgba(125, 125, 125, 0.62);
      -moz-box-shadow: 4px 11px 16px -5px rgba(125, 125, 125, 0.62);
      position: relative;
    }

    /* Responsive for 768px screens */
    @media screen and (max-width: 768px) {
      .bannerVideo {
        width: 100%; /* Use full width for smaller screens */
        margin-bottom: 50px; /* Adjust margin for smaller screens */
      }
    }

    /* Responsive for 480px screens */
    @media screen and (max-width: 480px) {
      .bannerVideo {
        width: 100%; /* Full width for mobile */
        margin-bottom: 40px; /* Adjust bottom margin further */
      }
    }

    .blockText {
      max-width: 100%;
      text-align: center;
      margin-bottom: 30px;
      @media (max-width: 1600px) {
        margin-bottom: 40px;
      }
      h2 {
        font-weight: bold;
        font-size: 36px;
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: #132636;
        margin: 0;
        margin-bottom: 15px;
        @media (max-width: 1600px) {
          font-size: 30px;
          line-height: 1.3;
          max-width: 468px;
          margin-left: auto;
          margin-right: auto;
        }
        @media (max-width: 768px) {
          font-size: 28px;
        }
        @media (max-width: 375px) {
          font-size: 22px;
        }
      }
      p {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 2;
        color: #132636;
        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
  }
  .servicePost {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-top: 40px;
    }
    > img {
      flex-shrink: 0;
      width: 70px;
      height: 70px;
    }
    .content {
      margin-left: 20px;
      h3 {
        margin: 0;
        font-weight: bold;
        font-size: 22px;
        line-height: 1.27;
        color: #132636;
        margin-bottom: 10px;
        @media (max-width: 425px) {
          font-size: 16px;
        }
      }
      p {
        font-weight: normal;
        font-size: 15px;
        line-height: 1.87;
        color: #132636;
        margin: 0;
      }
    }
    &:hover {
      .container-icon {
        animation: var(--shakeAnim);
      }
    }
  }
  .container-icon {
    width: var(--width);
    flex: 0 0 var(--width);
  }
  .product-icon {
    width: 85px;
  }
`;
export default OurProductArea;

export const Row = styled.div`
  margin-left: -75px;
  margin-right: -75px;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1600px) {
    margin-left: -185px;
    margin-right: -185px;
  }
  @media (max-width: 1600px) {
    padding-left: -95px;
    padding-right: -95px;
  }
  @media (max-width: 768px) {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 480px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
export const Col = styled.div`
  padding-left: 44px;
  padding-right: 44px;
  flex: 0 0 50%;
  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
  }
`;
