import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";

import Container from "../../../../common/components/UI/Container";
import { SECTION_FEATURES_DATA } from "../../../../common/data/SecD3v";
import { Modal } from "react-responsive-modal";
import React, { useState } from "react";
import KeyBenefitsArea from "./section.style";

const Section = () => {
  const { blockTitle, post } = SECTION_FEATURES_DATA;
  const { MainTitle, title } = blockTitle;

  const [open, setOpen] = useState(false);
  const onOpenModal = () => functionA();
  const onCloseModal = () => setOpen(false);

  const [benefitValue, setBenefitValue] = useState("");

  const retrieveBenefits = (title, description) => {
    setBenefitValue({ title, description });
    setOpen(true);
  };

  return (
    <KeyBenefitsArea id="benefits">
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content={MainTitle} />
          {/* <Text as="p" content={text} /> */}
        </Box>
      </Container>
    </KeyBenefitsArea>
  );
};

export default Section;
